import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { FormikHelpers, FormikProps } from 'formik';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';
import { groupSettingsSave } from '@wix/bi-logger-groups-data/v2';
import type { RichContent } from '@wix/ricos';
import {
  type Group,
  type CoverImage,
} from '@wix/ambassador-social-groups-v2-group/types';

import { selectGroup, selectIsGroupUpdating } from 'store/selectors';

import { useDidUpdate } from 'common/hooks';
import { useController } from 'common/context/controller';
import { EditGroupForm, IEditableGroup } from 'common/components/EditGroupForm';

import { Button } from 'wui/Button';
import { DialogContent } from 'wui/DialogContent';
import { DialogActions } from 'wui/DialogActions';

interface IDetailsTabProps {
  groupId: string;
  biExtra?: Record<string, any>;
  onClose(): void;
}

export function DetailsTab(props: IDetailsTabProps) {
  const { groupId, biExtra } = props;
  const { group$ } = useController();
  const { t } = useTranslation();

  const bi = useBi();

  const group = useSelector(selectGroup(groupId));
  const isUpdating = useSelector(selectIsGroupUpdating(groupId));

  const form = useRef<FormikProps<IEditableGroup>>(null);
  const [isBusy, setIsBusy] = React.useState(false);

  useDidUpdate(() => {
    if (!isUpdating) {
      props.onClose();
    }
  }, [isUpdating]);

  return (
    <>
      <DialogContent divider>
        <EditGroupForm
          innerRef={form}
          onSubmit={handleFormSubmit}
          onBusyStateChange={setIsBusy}
          data-hook="edit-group-form"
          initialValues={{
            name: group.name,
            privacyStatus: group.privacyStatus,
            coverImage: group.coverImage,
          }}
          withCoverImageSettings
        />
      </DialogContent>

      <DialogActions>
        <Button
          outlined
          variant="basic"
          fullWidth
          onClick={props.onClose}
          data-hook="details-cancel-button"
        >
          {t('groups-web.cancel')}
        </Button>
        <Button
          fullWidth
          variant="basic"
          disabled={isBusy || isUpdating}
          loading={isBusy || isUpdating}
          onClick={handleSave}
          data-hook="details-save-button"
        >
          {t('groups-web.save')}
        </Button>
      </DialogActions>
    </>
  );

  function handleSave() {
    form.current?.submitForm();
  }

  function handleFormSubmit(
    values: IEditableGroup,
    helpers: FormikHelpers<IEditableGroup>,
  ) {
    const data = values as Omit<Group, 'description'> & {
      description?: string;
    };

    group$.updateGroupInfo(groupId, data);
    const formikImage = form.current?.values.coverImage as CoverImage;
    const dataImage = data.coverImage;

    bi.report(
      groupSettingsSave({
        group_id: groupId,
        origin: 'settings',
        withPhoto: !!data.coverImage?.image,
        display_cover: formikImage.image?.mediaId !== dataImage?.image?.mediaId,
        edited_name: form.current?.values.name !== data.name,
        ...(biExtra || {}),
      }),
    );
  }
}

DetailsTab.displayName = 'DetailsTab';
